
const firebaseConfig = {
    apiKey: process.env.GATSBY_FB_API_KEY,
    authDomain: process.env.GATSBY_FB_AUTH_DOMAIN,
    projectId: process.env.GATSBY_FB_PROJECT_DOMAIN,
    storageBucket: process.env.GATSBY_FB_STORAGE_BUCKET,
    messagingSenderId: process.env.GATSBY_FB_MESSAGING_SENDER_Id,
    appId: process.env.GATSBY_FB_APP_ID,
    measurementId: process.env.GATSBY_FB_MEASUREMENT_ID
};

export {firebaseConfig}
