import PropTypes from 'prop-types'
import React from 'react'

const Header = props => (
  <header id="header" style={props.timeout ? { display: 'none' } : {}}>
    <div className="logo">
      <i className="icon fa-heart" onClick={() => {
        props.onOpenArticle('easteregg')
      }}></i>
    </div>
    <div className="content">
      <div className="inner">
        <h1>Heather & Thomas</h1>
        <p>
          Saturday June 18th, 2022
        </p>
        <p>
        Victoria, British Columbia
        </p>
      </div>
    </div>
    <nav>
      <ul>
        <li>
          <button
            onClick={() => {
              props.onOpenArticle('day1')
            }}
          >
            Photos
          </button>
        </li>
      </ul>
    </nav>
  </header>
)

Header.propTypes = {
  onOpenArticle: PropTypes.func,
  timeout: PropTypes.bool,
}

export default Header
