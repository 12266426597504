import React, { useState } from "react";
import { useStaticQuery, graphql } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { Lightbox } from "react-modal-image";


const PhotoContainer = () => {
    // if the lightBoxImage is null then don't show the lightbox
    const [lightBoxImage, setLightBoxImage] = useState(null);

    /**
     * Query hook, this could be separated out into another file just for hooks
     */
    const data = useStaticQuery(graphql`
    {
        allFile(
            filter: {sourceInstanceName: {eq: "gallery_main"}}
            sort: {fields: name}
          ) {
            edges {
              node {
                id
                name
                childImageSharp {
                  gatsbyImageData(
                    placeholder: DOMINANT_COLOR
                    layout: CONSTRAINED
                    formats: [AUTO, WEBP]
                    quality: 10
                  )
                }
              }
            }
          }
    }
    `)

    var imageArray = data.allFile.edges.map(edge => {
        const image = getImage(edge.node); // same as edge.node.childImageSharp.gatsbyImageData
        return (
            <li className="photoCard"
                key={edge.node.id}>
                <GatsbyImage className="gatsby_image"
                            image={image}
                            alt=""
                            onClick={() => {
                                setLightBoxImage(image)}
                            }
                />
            </li>
    )
    })

    /**
     *
     * @param {gatsbyImageData} gatsbyImage
     * @returns a string with the image path to
     *
     * the popped string is of the form `/path/to/image 2000w` and we only want the path
     */
    function getLargestSource(gatsbyImage) {
        return gatsbyImage.images.sources[0].srcSet.split(',').pop().split(' ')[0];
    }

    return (
        <>
            <ul className="photoContainer">
                {imageArray}
            </ul>
            {lightBoxImage !== null && (
                <Lightbox
                    hideDownload={true}
                    hideZoom={true}
                    large={getLargestSource(lightBoxImage)}
                    onClose={() => {
                        setLightBoxImage(null);
                    }}
                />
            )}
        </>
    )
}

export default PhotoContainer