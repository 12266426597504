import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

const EasterEggPhotosContainer = () => {
    const data = useStaticQuery(graphql`
    {
        allFile(filter: {sourceInstanceName: {eq: "gallery"}}) {
        edges {
            node {
            id
            name
            childImageSharp {
                gatsbyImageData(
                placeholder: DOMINANT_COLOR
                layout: CONSTRAINED
                formats: [AUTO, WEBP]
                quality: 95
                )
            }
            }
        }
        }
    }
    `)

    let imageArray = data.allFile.edges.map((edge, idx, arr) => {
        const image = getImage(edge.node); // same as edge.node.childImageSharp.gatsbyImageData
        return (
            <span className="image main" key={edge.node.id}>
                <GatsbyImage image={image}
                            alt=""
                />
                { (edge.node.name === "HalloweenTandMe") ? <label> First photo together. <br />Halloween, 2011.</label> : null}

            </span>
    )
    });

    return imageArray;
}

export default EasterEggPhotosContainer;